@import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import '../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import "chartist/chartist";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "theme/variables";
@import "./style.scss";
@import "theme/responsive";
@import "todo/todo";
@import '../../../node_modules/ngx-toastr/toastr.css';
@import 'sweetalert/sweetalert2.scss';
// @import "~dragula/dist/dragula.css";
@import "sticky/sticky.scss";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.css";
@import "~angular-custom-tour/styles/main.css";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~leaflet/dist/leaflet.css';

